import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"

export default () => {
  const data = useStaticQuery(graphql`
  query {
    avatar: file(relativePath: { eq: "avatar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  
  return (
  <Layout pageMeta={{ title: "Home", description: "BradSlocum.dev is the personal website of software developer, Brad Slocum." }} >
    <div class="flex-inline">
      <div class="max-w-sm px-4">
        <Img fluid={data.avatar.childImageSharp.fluid} />
      </div>
      <div class="px-4 py-4">
        <h1 class="text-2xl pb-4">Hi, I'm Brad!</h1>
        <p>I am a software developer living in the Indianapolis area. </p>
        <br/>
        <p> I lead a firmware team developing electronic lock products. I previously worked in automotive electronics.</p>
        <br/>
        <p>I am an avid board gamer and play many strategy games with my spouse. We are particularly fond of Wingspan, Terraforming Mars and Gloomhaven.</p>  
        <br/>
        <p>I am a parent of a preschooler and excited to watch my child grow into an awesome human. </p>  
        <br/>
        <p>
        Send me an email at: <a href="mailto:brad@bradslocum.dev"><strong>brad@bradslocum.dev</strong></a>.
        </p>
      </div>
    </div>
  </Layout>)
}
